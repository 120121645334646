import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Users from "../pages/users";
import Diagnostics from "../pages/diagnostics";
import Organisations from "../pages/organisations";
import Analytics from "../pages/analytics";
import PromoCodes from "../pages/promo-codes";
import Libraries from "../pages/libraries";
import ImageSearch from "../pages/image-search";
import Campustore from "../pages/campustore";
import "../../shared/scss/colors.css";
import "./main.scss";

export default function Main() {
  return (
    <div className="main">
      <Routes>
        <Route path="users">
          <Route path="diagnostics" element={<Diagnostics />} />
          <Route path=":userIdOrEmail?" element={<Users />} />
        </Route>
        <Route
          path="/organisations/*"
          element={<Organisations/>}
        />
        <Route
          path="/libraries/:libraryId?"
          element={<Libraries/>}
        />
        <Route
          path="/analytics/:domain?"
          element={<Analytics/>}
        />
        <Route path="/promo-codes" element={<PromoCodes/>} />
        <Route
          path="/image-search/*"
          element={<ImageSearch/>}
        />
        <Route path="/campustore" element={<Campustore/>} />
        <Route path="/" element={<Navigate to="users" replace/>}/>
      </Routes>
    </div>
  );
}
