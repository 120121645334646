import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import {
  organisationsActions,
  getOrganisations,
  getSortBy,
  getTotalCount,
} from "../../../../core/organisations";
import { notificationsActions } from "../../../../core/notifications";
import Header, { HeaderActions } from "../../header";
import FlatButton from "../../button/flat";
import HeaderButton from "../../button/header";
import Content from "../../content";
import Table from "../../table";
import Licenses from "./licenses";
import MoreOptionsMenu from "../../ui/more-options-menu";
import MiniMenuItem from "../../ui/mini-menu/item";
import EditIcon from "../../../../images/svg/edit.svg";
import DeleteIcon from "../../../../images/svg/remove.svg";
import DeleteConfirmation from "./delete-confirmation";
import OrganisationIcon from "../../../../images/svg/organisation.svg";
import { tsmOptions } from "../add-edit";
import SvgIcon from "../../ui/svg-icon";
import TickIcon from "../../../../images/svg/tick.svg";
import { applyLicenseToDomain } from "../../../../core/organisations/orgs-api";
import { debounce } from "../../../../utils";

const DATE_FORMAT = "Do MMM YYYY";

function OrgTable(props) {
  const {
    organisations,
    addErrorMessage,
    addNotification,
    totalCount,
    getOrganisationsPagination,
  } = props;

  const tableData = {
    headings: [
      {
        name: "Name",
        sortable: "name",
      },
      {
        name: "Start",
        sortable: "startDate",
      },
      {
        name: "End",
        sortable: "endDate",
      },
      {
        name: "Licenses",
      },
      {
        name: "Deal",
        sortable: "dealAmount",
      },
      {
        name: (
          <span>
            Teachers <small>(added)</small>
          </span>
        ),
        width: 100,
      },
      {
        name: (
          <span>
            Teachers <small>(signed in)</small>
          </span>
        ),
        width: 100,
      },
      {
        name: "TSM",
        sortable: "tsm",
        width: 100,
      },
      {
        name: "Pilot",
        sortable: "isPilot",
        width: 100,
      },
      {
        name: "Demo",
        sortable: "demo",
        width: 100,
      },
      {
        name: "reseller",
        sortable: "reseller",
        width: 100,
      },
      {
        name: "Billing",
        width: 100,
      },
      {
        name: "",
        width: 75,
      },
    ],
    body: null,
  };

  const [sortBy, setSortBy] = useState(
    props.sortBy ? props.sortBy : props.defaultSortBy
  );
  const localPageSize = parseInt(localStorage.getItem("tablePageSize"), 10);
  const [pageSize, setPageSize] = useState(localPageSize || 10);
  const [currentPage, setCurrentPage] = useState(0);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState([
    {
      label: "TSM",
      key: "tsm",
      value: null,
      options: tsmOptions,
    },
  ]);
  const [orgToDelete, setOrgToDelete] = useState(null);
  const filtersReadyRef = useRef();

  // Store TSM filter in localStorage
  useEffect(() => {
    if (!filtersReadyRef.current) {
      try {
        const storedTsm = localStorage.getItem("tsm");
        if (storedTsm) {
          setFilters(
            filters.map(filter =>
              filter.key === "tsm" ? { ...filter, value: storedTsm } : filter
            )
          );
        }
      } catch (err) {}
    }
    try {
      const tsm = filters.find(f => f.key === "tsm").value;
      if (tsm) {
        localStorage.setItem("tsm", tsm);
      } else if (localStorage.getItem("tsm") && filtersReadyRef.current) {
        localStorage.removeItem("tsm");
      }
    } catch (err) {}
    filtersReadyRef.current = true;
  }, [filters]);

  useEffect(() => {
    const searchFilter = filters
      .filter(f => f.value)
      .map(({ key, value }) => ({ key, value }));

    getOrganisationsPagination(
      pageSize,
      currentPage * pageSize,
      debouncedSearch,
      searchFilter,
      sortBy
    );
  }, [
    getOrganisationsPagination,
    pageSize,
    currentPage,
    debouncedSearch,
    filters,
    sortBy,
  ]);

  // debounce the search a bit to avoid multiple requests per keystroke
  useEffect(() => {
    const trimmedSearch = search.trim().toLowerCase();
    debounce(setDebouncedSearch, 500)(trimmedSearch);
  }, [search]);

  const handleFilterChange = ({ label, value }) => {
    setFilters(
      filters.map(filter =>
        filter.label === label ? { ...filter, value } : filter
      )
    );
  };

  const getBillingLink = org => {
    const { xeroId, quoteId, id } = org;
    if (xeroId && xeroId.indexOf("https://") > -1) {
      return (
        <a href={xeroId} target="_blank" rel="noreferrer">
          xero
        </a>
      );
    } else if (xeroId) {
      return (
        <a
          href={`https://go.xero.com/Contacts/View/${xeroId}`}
          target="_blank"
          rel="noreferrer">
          xero
        </a>
      );
    } else if (quoteId) {
      return (
        <a
          href={`https://payments.bookcreator.com/v1/quotes/${quoteId}/pdf`}
          target="_blank"
          rel="noreferrer">
          quote
        </a>
      );
    } else {
      return (
        <a
          href={`https://bookcreator.recurly.com/accounts/${id}`}
          target="_blank"
          rel="noreferrer">
          recurly
        </a>
      );
    }
  };

  const handleApplyLicenseToDomains = async ({ orgId, domains }) => {
    let fails = 0;
    for (let i = 0; i < domains.length; i++) {
      const domain = domains[i].domain;
      const res = await applyLicenseToDomain({ orgId, domain });
      if (!res.ok) {
        fails++;
        addErrorMessage("Licenses couldn't be applied");
      }
    }
    if (fails === 0) {
      addNotification("Licenses applied");
    }
  };

  const tick = (() => {
    return (
      <SvgIcon
        icon={TickIcon}
        style={{
          width: 12,
          height: 12,
          display: "block",
          margin: "0 auto ",
          fill: "var(--color--blue)",
        }}
      />
    );
  })();

  const getOrganisationRows = () => {
    return organisations.map(org => {
      const orgJS = org.toJS();
      const {
        demo,
        id,
        isPilot,
        name,
        startDate,
        endDate,
        licenses,
        dealAmount,
        membersCount,
        domains,
        reseller,
        tsm,
      } = orgJS;

      const { total: teachersAdded, joined: teachersSignedIn } = membersCount;

      return {
        ...orgJS,
        selected: false,
        sortData: {
          demo,
          id,
          name: name?.toLowerCase(),
          isPilot,
          startDate,
          endDate,
          dealAmount,
          reseller,
          tsm: tsm || "",
        },
        rowId: id,
        cells: [
          <span
            title={id}
            style={{ cursor: "context-menu" }}
            onClick={() => navigator.clipboard.writeText(id)}>
            {name}
          </span>,
          <span style={{ fontSize: 12 }}>
            {startDate ? moment(startDate).format(DATE_FORMAT) : null}
          </span>,
          <span style={{ fontSize: 12 }}>
            {endDate ? moment(endDate).format(DATE_FORMAT) : null}
          </span>,
          <Licenses licenses={licenses} />,
          <span>
            {dealAmount
              ? dealAmount
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                  .split(".")[0]
              : `$0`}
          </span>,
          <span>{teachersAdded}</span>,
          <span>{teachersSignedIn}</span>,
          <span>{tsm}</span>,
          <span>{isPilot ? tick : ""}</span>,
          <span>{demo ? tick : ""}</span>,
          <span>{reseller ? tick : ""}</span>,
          <span>{getBillingLink(org)}</span>,
          <MoreOptionsMenu>
            <MiniMenuItem to={`/organisations/${id}/edit`} icon={EditIcon}>
              Edit
            </MiniMenuItem>
            {domains ? (
              <MiniMenuItem
                onClick={() =>
                  handleApplyLicenseToDomains({ orgId: id, domains })
                }
                icon={OrganisationIcon}>
                Apply license to all domain users
              </MiniMenuItem>
            ) : null}
            <MiniMenuItem
              onClick={() => setOrgToDelete(org)}
              icon={DeleteIcon}
              type="negative">
              Delete
            </MiniMenuItem>
          </MoreOptionsMenu>,
        ],
      };
    });
  };

  const td = {
    ...tableData,
    body: getOrganisationRows(),
  };

  return (
    <div>
      <Header title="Organisations">
        <HeaderActions right>
          <FlatButton link="/organisations/deleted">Deleted</FlatButton>
          <HeaderButton href="/organisations/new">
            Add organisation
          </HeaderButton>
        </HeaderActions>
      </Header>
      <Content>
        <Table
          data={td}
          search={search}
          filters={filters}
          onFilterChange={handleFilterChange}
          setSearch={setSearch}
          sortBy={sortBy}
          rowHeight={58}
          onHeadingClick={setSortBy}
          externalPagination={{
            pageSize,
            onPageChange: setCurrentPage,
            onPageSizeChange: size => {
              setPageSize(size);
              setCurrentPage(0);
            },
            totalCount,
          }}
        />
      </Content>
      {orgToDelete ? (
        <DeleteConfirmation
          {...orgToDelete}
          onClose={() => setOrgToDelete(null)}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = createSelector(
  getOrganisations,
  getSortBy,
  getTotalCount,
  (organisations, sortBy, totalCount) => ({
    organisations,
    sortBy,
    totalCount,
  })
);

const mapDispatchToProps = {
  ...organisationsActions,
  ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgTable);
